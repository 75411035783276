
import React, {Fragment} from 'react';

import Lottie from 'lottie-react';
import animationData from '../../data/Seccion_5/data.json';

import "./Seccion_8.css"

const Seccion_8 = (props) => {


    const style = {
        top: 0,
        margin: '0px 0px 0px 0px',
        padding: '0px 0px 0px 0px',
        border: '0px 0px 0px 0px',
        height: "100vh",
        width: '100%',
        transform: "translate(0%, 0%)",
      };  

    return (
        <Fragment>
            <Lottie 
                loop
                autoplay
                animationData={animationData}
                style={style}
            />

            <object id="textoSeccion8" type="image/svg+xml" data="./img/Fotos.svg"></object>
        </Fragment>

    );
}
 
export default Seccion_8