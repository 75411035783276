import React, {useEffect, useState, Fragment} from 'react'
import { useLocation } from "react-router-dom";

import "./HorizontalScrollEcard.css";

import MensajeContextoProvider from "./Mensaje/MensajeContextoProvider";

// Json Lotties
import Seccion_1 from './Seccion_1/Seccion_1'
import Seccion_2 from './Seccion_2/Seccion_2'
import Seccion_3 from './Seccion_3/Seccion_3'
import Seccion_4 from './Seccion_4/Seccion_4'
import Seccion_5 from './Seccion_5/Seccion_5'
import Seccion_6 from './Seccion_6/Seccion_6'
import Seccion_7 from './Seccion_7/Seccion_7'
import Seccion_8 from './Seccion_8/Seccion_8'
import Seccion_9 from './Seccion_9/Seccion_9'
import Seccion_10 from './Seccion_10/Seccion_10'
import Funnel from '../funnel/Funnel';

const HorizontalScrollEcard = (props) => {

    let {search} = useLocation();
    let query = new URLSearchParams(search);

    const [altura, setAltura] = useState(0)
    const [ancho, setAncho]   = useState(0)
    // inside component:
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        setAltura( window.innerHeight )
        const anchoAux = (window.innerHeight * 57) / 100
        setAncho( anchoAux )
        //console.log( "altura: " + altura + "ancho: " + ancho  )
    }, [altura, ancho])


    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
        //console.log(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    return ( 
        <Fragment>
            <MensajeContextoProvider>
                <div id="fondoPantalla">
                    <div id="uno" className="tarjeta" >
                        <Seccion_1 altura={altura} ancho={ancho} scrollPosition={scrollPosition}/>
                    </div>
                    <div id="dos" className="tarjeta">
                        <Seccion_2 altura={altura} ancho={ancho} scrollPosition={scrollPosition}/>
                    </div>
                    <div id="tres" className="tarjeta">
                        <Seccion_3 altura={altura} ancho={ancho} scrollPosition={scrollPosition}/>
                    </div>
                    <div id="cinco" className="tarjeta">
                        <Seccion_5 altura={altura} ancho={ancho} scrollPosition={scrollPosition}/>
                    </div> 
                    <div id="sies" className="tarjeta">
                        <Seccion_6 altura={altura} ancho={ancho} scrollPosition={scrollPosition}/>
                    </div>
                     <div id="siete" className="tarjeta">
                        <Seccion_7 altura={altura} ancho={ancho} scrollPosition={scrollPosition}/>
                    </div>  
                    <div id="ocho" className="tarjeta">
                        <Seccion_8 altura={altura} ancho={ancho} scrollPosition={scrollPosition}/>
                    </div>  
                    <div id="nueve" className="tarjeta">
                        <Seccion_9 altura={altura} ancho={ancho} scrollPosition={scrollPosition}/>
                    </div>  
                    <div id="dies" className="tarjeta">
                        <Seccion_10 altura={altura} ancho={ancho} scrollPosition={scrollPosition}/>
                    </div>   
                </div>
                {query.get("mkt") === null ? "" :  <Funnel/> } 
            </MensajeContextoProvider>
        </Fragment>                  
    );

}
 
export default HorizontalScrollEcard;