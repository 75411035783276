import React from 'react'

import "./FormularioModal.css"

const FormularioModal = ( props ) => {

    const handleInputChange = event => {
        console.log(event)
        const { name, value } = event.target
        props.setInvitado({ ...props.invitado, [name]: value })
        console.log(props.invitado);
    }

    const handleCheckBoxChange = event => {
        //console.log(event.target.checked)
        const { name, checked } = event.target
        props.setInvitado({ ...props.invitado, [name]: (checked? '1' : '0') })
        //console.log(invitado);
    }      

    const handleRadioButtonChange = event => {
        //console.log(event.target.checked)
        const { name, value } = event.target
        //alert(value)
        props.setInvitado({ ...props.invitado, [name]: value })
        //console.log(invitado);
    }      


    return ( 
        <div className="form-group">
            <h1 class="text-center">Daniel & Rocio</h1>

            <label htmlFor="campo1">Nombres de los invitados</label>
            <span>: &nbsp; </span>
            <input className="form-control" type="text"
                name="campo1" id="campo1"
                onChange={handleInputChange} value={props.invitado.campo1}
            />
            <label htmlFor="campo2" style={{ marginTop: `7px` }}>Cantidad de adultos</label>
            <span>: &nbsp; </span>
            <select className="custom-select mr-sm-2 combos" id="campo2" name="campo2" 
                    onChange={handleInputChange} 
                    value={props.invitado.campo2}>
                <option value={0}>0</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
                <option value={10}>10</option>
            </select>
            <br />
            <label htmlFor="campo3" style={{ marginTop: `7px` }}>Cantidad de ni&ntilde;os</label>
            <span>: &nbsp; </span>
            <select className="custom-select mr-sm-2 combos" id="campo3" name="campo3" 
                    onChange={handleInputChange} 
                    value={props.invitado.campo3}>
                <option value={0}>0</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
                <option value={10}>10</option>
            </select>
            
            <hr size="5" width="100%" ></hr>  

            <label htmlFor="campo4" style={{ marginTop: `7px` }}>Alergias / intolerancias / veganos</label>
            <input className="form-control" type="text"
                name="campo4" id="campo4"
                onChange={handleInputChange} value={props.invitado.campo4}
            /> 

            <label htmlFor="campo5" style={{ marginTop: `7px` }}>Propón una canción</label>
            <input className="form-control" type="text"
                name="campo5" id="campo5"
                onChange={handleInputChange} value={props.invitado.campo5}
            /> 

<hr size="5" width="100%" ></hr>

            <strong>Necesitas autobús de ida:&nbsp;</strong>
            <br></br>
            <label> 
                <input
                name="campo6" id="campo6"
                checked={props.invitado.campo6 === "No" ? true : false}
                type="radio"
                value="No"
                onChange={handleRadioButtonChange}
                />&nbsp;No
            </label>
            &nbsp;&nbsp;/&nbsp;&nbsp;
            <label> 
                <input
                name="campo6" id="campo6"
                checked={props.invitado.campo6 === "Si" ? true : false}
                type="radio"
                value="Si"
                onChange={handleRadioButtonChange}
                />&nbsp;Si
            </label>

            { props.invitado.campo6 === 'Si' ? 
                <>
                    <br />
                    <label htmlFor="campo7" style={{ marginTop: `7px` }}>Seleccionar parada:</label>
                    <span>: &nbsp; </span>
                    <select className="custom-select mr-sm-2 combos" id="campo7" name="campo7" 
                            onChange={handleInputChange} 
                            value={props.invitado.campo7}>
                        <option value={0}>Madrid Rio</option>
                        <option value={1}>La Gavia</option>
                    </select>
                </>
            : "" }

<hr size="5" width="100%" ></hr>

            <strong>Necesitas autobús de vuelta:&nbsp;</strong>
            <br></br>
            <label> 
                <input
                name="campo8" id="campo8"
                checked={props.invitado.campo8 === "No" ? true : false}
                type="radio"
                value="No"
                onChange={handleRadioButtonChange}
                />&nbsp;No
            </label>
            &nbsp;&nbsp;/&nbsp;&nbsp;
            <label> 
                <input
                name="campo8" id="campo8"
                checked={props.invitado.campo8 === "Si" ? true : false}
                type="radio"
                value="Si"
                onChange={handleRadioButtonChange}
                />&nbsp;Si
            </label>

        </div>
     );
}
 
export default FormularioModal;