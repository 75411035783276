import React, {Fragment} from 'react';

import Lottie from 'lottie-react';
//import animationData from '../../data/Seccion_7/data.json';
import animationData from '../../data/Seccion_8/data.json';
import "./Seccion_7.css"

const Seccion_7 = (props) => {

    const styleImage = {
        top: 0,
        padding: 0,
        border: 0,
        height: "100vh",
        position: "absolute",
        margin: "auto",
        transform: "translate(-50%, 600%)",
        zIndex: "-100"
      };

    const style = {
        top: 0,
        margin: '-1px 0px 0px 0px',
        padding: '0px 0px 0px 0px',
        border: '0px 0px 0px 0px',
        height: "100vh",
        width: '100%',
        transform: "translate(0%, 0%)",
      };  

    return (
        <Fragment>
            <Lottie 
                loop
                autoplay
                animationData={animationData}
                style={style}
            />
            {/* <img id="fonddoSeccion6" src="./imgFondos/6.jpg" style={styleImage}></img> */}

            
        </Fragment>

    );
}
 
export default Seccion_7